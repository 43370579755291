define("discourse/plugins/discourse-text-direction/discourse/initializers/text-direction-ui", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor"], function (_exports, _pluginApi, _dEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const siteSettings = api.container.lookup('site-settings:main');
    if (siteSettings.discourse_text_direction_enabled) {
      api.onToolbarCreate(toolbar => {
        toolbar.addButton({
          id: "text_direction_rtl_button",
          group: "extras",
          title: 'composer.text_direction_rtl_label',
          icon: "align-right",
          perform: e => e.applySurround('[text-direction=rtl]\n', '\n[/text-direction]\n', 'text_direction_rtl_default_text')
        });
      });
    }
  }
  var _default = _exports.default = {
    name: 'text-direction-ui',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', api => initializePlugin(api), {
        noApi: () => priorToApi(container)
      });
    }
  };
});